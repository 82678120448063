* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  overflow-x: hidden; /* 100vw에서 생기는 가로스크롤 문제 해결 */
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.lottery-container-wrapper {
  width: 100vw; /* 전체 화면 너비 */
  display: flex;
  justify-content: center; /* 자식 요소를 중앙에 배치 */
  align-items: center; /* 자식 요소를 세로로 중앙에 배치 */
  flex: 1;
}

.lottery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 1200px;
  width: 1200px;
  padding: 50px 0 50px 0;
}

.lotto-number {
  font-size: 2rem; /* 로또 번호 글자 크기 */
  text-align: center;
  margin-top: 20px;
}

.animated-button {
  font-size: 1rem; /* 버튼 글자 크기 */
  padding: 12px 24px; /* 버튼 안쪽 여백 */
  background-color: #ff5722; /* 밝은 오렌지 색상 */
  color: #fff; /* 버튼 글자 색상 */
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 25px; /* 둥글게 */
  transition: transform 0.2s ease, background-color 0.3s ease,
    box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* 부드러운 그림자 */
}

.animated-button:hover {
  background-color: #e64a19; /* 호버 시 색상 변경 */
  transform: scale(1.05); /* 호버 시 확대 효과 */
}

.animated-button:active {
  background-color: #d84315; /* 클릭 시 색상 변경 */
  transform: scale(0.98); /* 클릭 시 축소 효과 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* 클릭 시 그림자 감소 */
}

.numberlist-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.numberlist {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc; /* 번호 묶음을 구분하는 테두리 추가 */
  border-radius: 5px;
  display: inline-block; /* 한 줄로 묶음 */
  cursor: pointer;
  position: relative;
  margin: 5px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* 호버 효과 추가 */
}

.numberlist:hover {
  background-color: #f0f0f0; /* 호버 시 배경 색상 변경 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 호버 시 그림자 추가 */
}

.numberlist-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 5px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
}

.mini-description {
  font-size: 0.7rem;
}

.ball_type1 {
  background-color: #f2b720;
}

.ball_type2 {
  background-color: #4072ac;
}

.ball_type3 {
  background-color: #de4c0e;
}

.ball_type4 {
  background-color: #9195a4;
}

.ball_type5 {
  background-color: #13be4b;
}

/* 하이라이트 스타일 */
.highlight {
  border: 2px solid gold;
  background-color: #ffeb3b;
  position: relative;
  animation: shine 1s infinite alternate;
}

@keyframes shine {
  0% {
    box-shadow: 0 0 5px rgba(255, 215, 0, 0.6);
  }
  100% {
    box-shadow: 0 0 20px rgba(255, 215, 0, 1);
  }
}

.highlight-message {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  background-color: gold;
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translate(-50%, -20px);
  }
  50% {
    transform: translate(-50%, -30px);
  }
}

.ultra-highlight {
  border: 3px solid red;
  background-color: #ffeb3b;
  position: relative;
  animation: ultra-shine 1s infinite alternate, ultra-explode 0.5s ease-out 0s 1,
    ultra-pulse 1.5s infinite;
}

@keyframes ultra-shine {
  0% {
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.8);
  }
  100% {
    box-shadow: 0 0 30px rgba(255, 0, 0, 1);
  }
}

@keyframes ultra-explode {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes ultra-pulse {
  0%,
  100% {
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.8), 0 0 20px rgba(255, 0, 0, 0.6),
      0 0 30px rgba(255, 0, 0, 0.4);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 0, 0, 1), 0 0 30px rgba(255, 0, 0, 0.8),
      0 0 40px rgba(255, 0, 0, 0.6);
  }
}

.fixed-number-form {
  display: flex;
  align-items: center;
}

.fixed-number-form input {
  margin-right: 10px;
}

.fixed-number-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* 아이템 사이의 간격 */
  margin: 10px 0 0 0;
}

.fixed-number-item {
  color: #fff; /* 글자 색상 */
  padding: 8px 8px; /* 안쪽 여백 */
  border-radius: 50%; /* 원형 버튼 */
  font-size: 0.8rem; /* 글자 크기 */
  cursor: pointer;
  width: 20px; /* 고정된 너비 */
  height: 20px; /* 고정된 높이 */
  transition: background-color 0.3s ease, transform 0.2s ease; /* 애니메이션 */
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixed-number-item:hover {
  transform: scale(1.1); /* 호버 시 확대 효과 */
}

.fixed-number-item:active {
  transform: scale(0.95); /* 클릭 시 축소 효과 */
}

/* 고정번호추가 */
.fixed-number-form {
  display: flex;
  align-items: center;
  gap: 10px; /* 버튼과 입력 필드 사이의 간격 */
  position: relative;
}

.number-input {
  border: 1px solid #ccc; /* 연한 회색 테두리 */
  border-radius: 5px; /* 둥글게 */
  padding: 10px; /* 안쪽 여백 */
  font-size: 16px; /* 폰트 크기 */
  width: 150px; /* 너비 조정 */
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* 애니메이션 효과 */
}

.number-input::placeholder {
  color: #888; /* 회색 placeholder 텍스트 */
  font-size: 10px !important;
}

.number-input:focus {
  border-color: #007bff; /* 포커스 시 테두리 색상 */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* 포커스 시 그림자 추가 */
  outline: none; /* 기본 포커스 테두리 제거 */
}

.add-fixed-number-button {
  background-color: #28a745; /* 녹색 배경 */
  color: white; /* 흰색 글씨 */
  border: none;
  border-radius: 25px; /* 둥글게 */
  padding: 8px 16px; /* 안쪽 여백 */
  font-size: 12px; /* 폰트 크기 */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease; /* 애니메이션 효과 */
  position: relative; /* 버튼을 기준으로 에러 아이콘 위치 설정 */
}

.add-fixed-number-button:hover {
  background-color: #218838; /* 호버 시 색상 변경 */
}

.add-fixed-number-button:active {
  background-color: #1e7e34; /* 클릭 시 색상 변경 */
  transform: scale(0.98); /* 클릭 시 버튼 축소 효과 */
}

.fixed-number-container {
  border: 2px solid #ddd; /* 연한 회색 테두리 */
  border-radius: 10px; /* 둥글게 */
  padding: 15px; /* 안쪽 여백 */
  margin: 20px 0; /* 상하 여백 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 부드러운 그림자 */
  background-color: #f9f9f9; /* 배경색 */
}

.number-input::placeholder {
  color: #aaa; /* 플레이스홀더 색상 */
  font-size: 0.7rem; /* 플레이스홀더 글자 크기 조정 */
}

/* Error icon styles */
.error-icon {
  position: absolute;
  top: 100%; /* 버튼의 아래쪽 끝에 위치하도록 설정 */
  right: 0; /* 버튼의 오른쪽 끝에 위치하도록 설정 */
  transform: translateX(0) translateY(10px); /* 에러 아이콘이 버튼 아래쪽으로 이동하도록 설정 */
  display: flex;
  align-items: center;
  z-index: 10; /* 에러 메시지가 버튼 위에 위치하도록 설정 */
}

.error-message {
  color: #e74c3c;
  font-size: 0.8rem;
  background-color: #fff;
  padding: 5px 10px;
  border: 1px solid #e74c3c;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  top: 100%; /* 에러 메시지가 에러 아이콘 바로 아래에 위치하도록 설정 */
  left: 0; /* 에러 메시지가 에러 아이콘 바로 왼쪽에 위치하도록 설정 */
  margin-top: 5px; /* 에러 메시지와 아이콘 사이의 간격 설정 */
}

.copy-success-message {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 0.8rem;
  transform: translate(-50%, 20%);
  background-color: #fff;
  border: 1px solid #e74c3c;
  color: #e74c3c;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  z-index: 10;
}
