.title {
  font-size: 1rem; /* 제목 글자 크기 */
  font-weight: bold; /* 제목 굵기 */
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #333; /* 제목 글자 색상 */
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

/* title fade in fade out */
.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}
