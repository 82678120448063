.header-wrapper {
  width: 100vw;
  background-color: #0d0c1d; /* 블랙 배경색 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 그림자 효과 */
}

/* 헤더 스타일 */
.header {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* 로고 스타일 */
.logo {
  margin-left: 10px;
  font-size: 32px;
  font-weight: bold;
  color: #8b0000; /* 다크 레드 텍스트 */
}

/* 메뉴 스타일 */
.menu {
  display: flex;
  gap: 20px;
}

.menu a {
  font-size: 18px;
  color: #fff; /* 흰색 텍스트 */
  cursor: pointer;
  transition: color 0.3s ease;
  text-decoration: none;
}

.menu a:hover {
  color: #8b0000; /* 다크 레드로 호버 효과 */
}

/* 햄버거 메뉴 스타일 */
.hamburger-menu {
  display: none; /* 기본적으로 숨김 */
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  margin: 0 10px 0 0;
}

/* 모바일 스타일 */
@media (max-width: 768px) {
  .header {
    position: relative;
  }

  .logo {
    font-size: 24px;
  }

  .menu {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: fixed;
    top: 0;
    right: 0;
    width: 250px; /* 사이드바 너비 */
    height: 100vh; /* 사이드바 전체 높이 */
    background-color: #161b33;
    transform: translateX(100%); /* 기본적으로 숨김 */
    transition: transform 0.3s ease; /* 슬라이드 애니메이션 */
    padding: 20px;
    z-index: 1000; /* 사이드바가 헤더 위에 오도록 설정 */
  }

  .menu.open {
    transform: translateX(0); /* 메뉴가 오른쪽에서 슬라이드되어 나타남 */
  }

  .main-menu {
    background-color: #0d0c1d;
    font-size: 12px; /* 대메뉴 크기 */
    color: #fff; /* 흰색 텍스트 */
    cursor: pointer;
    border-radius: 8px;
    padding: 5px 0 5px 0;
  }

  .main-menu span:first-child {
    font-size: 10px;
    margin-left: 12px;
    color: gray;
  }

  .sub-menu {
    display: flex;
    flex-direction: column;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.5s ease, transform 0.5s ease; /* 애니메이션 추가 */
  }

  .menu.open .sub-menu {
    opacity: 1;
    transform: translateY(0);
  }

  .sub-menu a {
    font-size: 16px; /* 서브메뉴 크기 */
    color: #fff; /* 흰색 텍스트 */
    cursor: pointer;
    transition: color 0.3s ease;
    text-decoration: none;
    padding: 14px 12px;
    margin: 0 0 0 12px;
  }

  .sub-menu a span {
    font-size: 12px;
    color: gray;
  }

  .sub-menu:last-child {
    border-bottom: 1px solid gray;
    padding-bottom: 15px;
  }

  .sub-menu a:hover {
    color: #8b0000; /* 다크 레드로 호버 효과 */
  }

  .hamburger-menu {
    display: block; /* 모바일에서 햄버거 메뉴 보이기 */
  }

  /* 사이드 메뉴 닫기 버튼 */
  .close-button {
    background: none;
    border: none;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
    align-self: flex-end;
    margin-bottom: 20px; /* 버튼과 메뉴 항목 간의 간격 */
  }

  .close-button:hover {
    color: #8b0000; /* 다크 레드로 호버 효과 */
  }

  /* 슬라이드 애니메이션 */
  @keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

/* 웹 화면에서 사이드 메뉴 닫기 버튼 완전히 숨기기 */
@media (min-width: 769px) {
  .menu {
    display: flex; /* 웹 화면에서 기본 메뉴 보이기 */
    flex-direction: row; /* 가로 배치 */
  }

  .close-button {
    display: none; /* 웹 화면에서는 사이드 메뉴 닫기 버튼 숨기기 */
  }

  .main-menu {
    display: none; /* 웹 화면에서는 대메뉴 숨기기 */
  }

  .sub-menu {
    display: flex;
    gap: 20px;
  }

  .sub-menu span {
    display: none;
  }
}
