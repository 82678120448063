.footer-wrapper {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0d0c1d; /* 블랙 배경색 */
}

.footer {
  color: #fff; /* 흰색 텍스트 */
  text-align: center;
  padding: 20px 0;
  max-width: 1200px;
  width: 1200px;
  bottom: 0;
}

.footer p {
  margin: 0;
  font-size: 14px;
}
